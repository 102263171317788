export type LanguageCode = 'en' | 'no' | 'nl' | 'de';

type Language = {
	code: LanguageCode;
	file: `${LanguageCode}.ts`;
};

const languages: Language[] = [
	{
		code: 'en',
		file: 'en.ts',
	},
	{
		code: 'no',
		file: 'no.ts',
	},
	{
		code: 'nl',
		file: 'nl.ts',
	},
	{
		code: 'de',
		file: 'de.ts',
	},
];

export default languages;
